<!-- 密码弹窗组件 -->
<template>
  <!-- 密码弹窗 -->
  <van-popup position='bottom' round closeable v-model="popupPwd">
    <div style="width: 100%;height: 110vw;">
      <div style="font-size: 5vw;color: #1b1b1b;text-align: center;margin: 8vw 0 8vw 0;">请输入支付密码
      </div>
      <van-password-input :value="password" :focused="showKeyboard" @focus="showKeyboard = true" />
      <van-number-keyboard extra-key="00" v-model="password" :show="showKeyboard" @blur="showKeyboard = true" />
    </div>
  </van-popup>
</template>

<script>
import { Toast, Dialog } from 'vant';
import { Constants } from "@/utils/constants";
import { Api } from '@/utils/net/Api';
// 不导入也可以 为了看源码
import vanPasswordInput from 'vant/lib/password-input';
import vanNumberKeyboard from 'vant/lib/number-keyboard';
import EncryptUtils from '@/utils/EncryptUtils';
import 'vant/lib/dialog/style';
//输入格
import 'vant/lib/password-input/style';
// 数字键盘的样式
import 'vant/lib/number-keyboard/style';
export default {
  components: { vanPasswordInput, vanNumberKeyboard },
  data() {
    return {
      popupPwd: false,
      showKeyboard: true,
      password: '',
    }
  },
  methods: {
    //子组件的事件
    pwdCallback(value) {
      this.$emit('handleChange', value) // 触发父组件中handleChange事件并传参Jack
      // 注：此处事件名称与父组件中绑定的事件名称要一致
    },
    //检查密码是否正确
    checkPayPwd(value) {
      let params = {
        password: value,
      }
      let _this = this;
      Api.checkPayPwd(params).then(result => {
        if (result.code == 100) {
          //密码正确
          //将加密后密码回调出去
          _this.pwdCallback(value)
        } else {
          _this.password = ''
          Toast(result.msg);
        }
      })
    },
    // 设置交易密码
    setPwd() {
      Dialog.confirm({
        title: "请设置支付密码",
        message: '',
        confirmButtonText: "去设置支付密码"
      }).then(() => {
        //跳转到设置密码页面
        window.location.href = Constants.goToPayManager;
      }).catch(() => {
        console.log('cancel');
      })
    },
    // 是否设置支付密码
    apiIsPwd() {
      let _this = this;
      Api.hasTraderPwd({}).then(result => {
        console.log('data....result', result)
        if (result.code == 100) {
          let resData = result.data;
          let isExist = resData.isExist || false;
          if (isExist) {
            //存在密码 弹出输入密码
            _this.popupPwd = true
          } else {
            //不存在密码
            _this.setPwd()
          }
        } else {
          Toast(result.data.msg);
        }
      })
    },
    controlPwdDialogFunction(value) {
      console.log('父调用子的方法' + value);
      //如果显示密码弹窗
      if (value == true) {
        //隐藏密码输入 密码置空
        this.password = '';
        //判断是否设置密码
        this.apiIsPwd()
      } else {
        //存在密码 弹出输入密码
        this.popupPwd = false
      }
    }
  },
  watch: {
    password(value) {
      console.log(value)
      if (value.length === 6) {
        let password = EncryptUtils.getEncryptPayPwd(value)
        this.checkPayPwd(password);
      }
    },
    //弹窗消失和显示 值的监听  如果父组件需要此回调可以调用到上层
    showKeyboard(value) {
      console.log("弹窗消失:" + value);
    }
  },
  filters: {
    ellipsis(value) {
      if (!value) return ''
      if (value.length > 5) {
        return value.slice(0, 5) + '...'
      }
      return value
    }
  }
}
</script>

<style scoped>
.van-number-keyboard {
  background: #fff;
  padding-left: 4vw;
  padding-right: 4vw;
}

.van-password-input {
  background: #fff;
  padding-left: 4vw;
  padding-right: 4vw;
}

::v-deep .van-key {
  height: 15vw;
  font-size: 18px;
  background-color: #fff;
  border-radius: 0px;
  color: #000;
}

::v-deep .van-key__delete-icon {
  width: 24px;
  height: 14px
}

/* 调整线框 */
::v-deep .van-hairline--left::after {
  border-color: #DCDCDC;
  border-left-width: 1.5px;
}

::v-deep .van-hairline--surround::after {
  border-color: #DCDCDC;
  border-width: 1.5px;
}
</style>
